import React, { useEffect, useState } from 'react';
import leftarrow from '../assets/leftarrow.svg';
import rightarrow from '../assets/rightarrow.svg';

const Technologies = () => {
  const testimonies = [
    {
      id: 1,
      title: 'IBM',
      content: 'Proporciona servicios tecnológicos integrales a nivel global, abarcando desde la nube y análisis de datos hasta inteligencia artificial y computación cuántica. Su enfoque se centra en impulsar la innovación y la transformación digital para empresas en diversos sectores.',
    },
    {
      id: 2,
      title: 'Azure',
      content: 'Soluciones integrales que abarcan desde el alojamiento de aplicaciones hasta la inteligencia artificial. Proporciona herramientas flexibles y escalables para impulsar la innovación y la transformación digital.',
    },
    {
      id: 3,
      title: 'Acer',
      content: 'Ofrece una amplia gama de servicios tecnológicos que abarcan desde dispositivos y hardware hasta soluciones de software, proporcionando a los usuarios experiencias informáticas eficientes e innovadoras.',
    },
    {
      id: 4,
      title: 'Microsoft',
      content: 'Ofrece una amplia gama de productos y servicios, desde sistemas operativos hasta soluciones en la nube y aplicaciones empresariales.',
    },
  ];

  const [currentTestimony, setCurrentTestimony] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimony((prevTestimony) => (prevTestimony === 4 ? 1 : prevTestimony + 1));
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  const nextTestimony = () => {
    setCurrentTestimony((prevTestimony) => (prevTestimony === 4 ? 1 : prevTestimony + 1));
  };

  const prevTestimony = () => {
    setCurrentTestimony((prevTestimony) => (prevTestimony === 1 ? 4 : prevTestimony - 1));
  };

  return (
    <section className="testimony">
      <div className="testimony__container container">
        <img src={leftarrow} className="testimony__arrow" id="before" onClick={prevTestimony} />

        <section className={`testimony__body ${currentTestimony === testimonies[0].id ? 'testimony__body--show' : ''}`} data-id={testimonies[0].id}>
          <div className="testimony__texts">
            <p className="testimony__review">{testimonies[0].content}</p>
          </div>
          <figure className="testimony__picture">
            <h2 className="subtitle"><span className="testimony__course">{testimonies[0].title}</span></h2>
          </figure>
        </section>

        <section className={`testimony__body ${currentTestimony === testimonies[1].id ? 'testimony__body--show' : ''}`} data-id={testimonies[1].id}>
          <div className="testimony__texts">
            <p className="testimony__review">{testimonies[1].content}</p>
          </div>
          <figure className="testimony__picture">
            <h2 className="subtitle"><span className="testimony__course">{testimonies[1].title}</span></h2>
          </figure>
        </section>

        <section className={`testimony__body ${currentTestimony === testimonies[2].id ? 'testimony__body--show' : ''}`} data-id={testimonies[2].id}>
          <div className="testimony__texts">
            <p className="testimony__review">{testimonies[2].content}</p>
          </div>
          <figure className="testimony__picture">
            <h2 className="subtitle"><span className="testimony__course">{testimonies[2].title}</span></h2>
          </figure>
        </section>

        <section className={`testimony__body ${currentTestimony === testimonies[3].id ? 'testimony__body--show' : ''}`} data-id={testimonies[3].id}>
          <div className="testimony__texts">
            <p className="testimony__review">{testimonies[3].content}</p>
          </div>
          <figure className="testimony__picture">
            <h2 className="subtitle"><span className="testimony__course">{testimonies[3].title}</span></h2>
          </figure>
        </section>

        <img src={rightarrow} className="testimony__arrow" id="next" onClick={nextTestimony} />
      </div>
    </section>
  );
};

export default Technologies;

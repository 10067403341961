import React from 'react';
import ServiceSection from './Home/ServiceSection';
import Technologies from './Home/Technologies'
const Home = () => {
  return (
    <div>
      <br /><br /> <br />
      <ServiceSection />
      <br /><br /> <br />
      <Technologies />
    </div>
  );
};

export default Home;


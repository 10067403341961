
export const validateNombre = (nombre) => {
    if (!nombre) {
        return 'El nombre es obligatorio';
    } else if (!/^([a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+[ ]?)+$/.test(nombre)) {
        return 'El nombre debe contener solo letras y espacios';
    }
    return '';
};

export const validateApellido = (apellido) => {
    if (!apellido) {
        return 'El apellido es obligatorio';
    } else if (!/^([a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]+[ ]?)+$/.test(apellido)) {
        return 'El apellido debe contener solo letras y espacios';
    }
    return '';
};

export const validateEmail = (email) => {
    if (!email) {
        return 'El correo electrónico es obligatorio';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        return 'El correo electrónico no es válido';
    }
    return '';
};

export const validateTelefono = (telefono) => {
    if (!telefono) {
        return 'El teléfono es obligatorio';
    } else if (!/^(?:(\+34|0034|34)?[6-9]\d{8}|[6-9]\d{8})$/.test(telefono)) {
        return 'El formato del teléfono no es válido';
    }
    return '';
};


export const validateComentarios = (comentarios) => {
    if (!comentarios) {
        return 'Los comentarios son obligatorios';
    } else if (comentarios.length < 10) {
        return 'Los comentarios deben tener al menos 10 caracteres';
    } else if (comentarios.length > 500) {
        return 'Los comentarios no deben exceder los 500 caracteres';
    } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9,.!? ]+$/.test(comentarios)) {
        return 'Los comentarios contienen caracteres no permitidos';
    }
    return '';
};

export const validatePoliticaPrivacidad = (politicaPrivacidad) => {
    if (!politicaPrivacidad) {
        return 'Debes aceptar la política de privacidad';
    } else if (politicaPrivacidad !== true) {
        return 'Debes aceptar la política de privacidad para continuar';
    }
    return '';
};


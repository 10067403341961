import React from 'react';
import Service1Header from './Service1Section';
import ServicioPrimero from './ServicioPrimero';
import background1 from '../../assets/img/mantenimiento.jpg';
import background2 from '../../assets/img/soporte.jpg';
import background3 from '../../assets/img/soluciones.jpg';

const Service1 = () => {
  return (
    <div>
      <Service1Header /> 

      <section className="price container">
        <br></br><br></br><br></br><br></br>
        <h2 className="subtitle"style={{ color: '#0056b3' }}>¿CÓMO PODEMOS AYUDARTE?</h2>
        <p>Enfrentamos los desafíos tecnológicos empresariales con confianza, gracias a nuestros servicios de mantenimiento informático especializados. Desde garantizar la seguridad hasta impulsar la eficiencia operativa, estamos comprometidos a optimizar tu infraestructura tecnológica para un rendimiento máximo.</p>
        <br></br>
        <br></br>

        <div className="price__table">
          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background1})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>MANTENIMIENTO INTEGRAL</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Ofrecemos un servicio integral de mantenimiento informático para empresas, abarcando diagnósticos proactivos, actualizaciones de software y hardware, y soporte técnico continuo para garantizar un entorno informático óptimo.</p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${background2})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>SOPORTE EFICIENTE</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Nuestro servicio de mantenimiento informático para empresas incluye la gestión eficiente de incidencias, monitoreo de sistemas en tiempo real y asesoramiento personalizado, asegurando un rendimiento continuo y seguro de la infraestructura tecnológica.</p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(${background3})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>SOLUCIONES PERSONALIZADAS</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Proporcionamos soluciones de mantenimiento informático adaptadas a las necesidades de tu empresa, cubriendo desde la administración de redes y servidores hasta la optimización de equipos, brindando un soporte técnico ágil y eficaz.</p>
            </div>
          </div>
        </div>
      </section>

      <ServicioPrimero />
    </div>
  );
};

export default Service1;
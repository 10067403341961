// Contact.js
import React from 'react';
import ContactHeader from './Contact/ContactHeader';
import LegalWarning from './Legaly/LegalWarning';

const Legal = () => {
  return (
    <div>
      <ContactHeader />
      <LegalWarning />
    </div>
  );
};

export default Legal;
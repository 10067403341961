import React from 'react';
const LegalWarning = () => {
    return (
        <section className="container-about">
            <div className="info-section">
                <h1>Aviso Legal</h1>
            </div>
            <div className="input-fields-section-legal">
                <p>Las presentes Condiciones Generales de Uso, Condiciones de Venta y Política de Privacidad, regulan el uso del sitio web, “www.arriArceTCI.com”, propiedad   de ARRIARCE TCI, S.L -Inscrita en el Registro Mercantil de Madrid, Tomo 14.893, Libro 0, Folio 111, Sección 8, Hoja M-247.817, Inscripción 1ª - CIF  B-82499294 (en adelante ARRIARCE TCI).</p>
                <p>Estas condiciones establecen los derechos y obligaciones de todos los usuarios de nuestro sitio web, en relación con los productos y servicios que ofrecemos a través de nuestro sitio web www.arriArceTCI.com</p>
                <p>Estas Condiciones son importantes tanto para Usted como para nosotros puesto que han sido diseñadas para crear un acuerdo legalmente vinculante entre nosotros protegiendo sus derechos como cliente y los nuestros como empresa.</p>
                <p>Les rogamos que lean atentamente las presentes Condiciones y nuestra Política de Privacidad antes de confirmar su pago para realizar el pedido.</p>
                <p>Al utilizar el sitio web “www.arriArceTCI.com” o hacer un pedido a través del mismo, implica la aceptación por parte del usuario de los términos y condiciones establecidos en este Aviso Legal, debiendo cesar en su uso inmediatamente si no acepta alguno de ellos.
                   Estas Condiciones podrían ser modificadas, por lo que debe leerlas antes de efectuar cada pedido.</p>
                    
                <h4>Artículo 1. Propiedad Intelectual e Industrial</h4>
                <p>Los nombres, marcas, y logotipos propiedad de terceros, expuestos en este sitio web, están protegidos por sus respectivos dueños.</p>
                <p>ARRIARCE TCI y/o sus respectivos proveedores no se hacen responsables de la exactitud de la información  contenida en los documentos  y  gráficos relacionados que se encuentran publicados en este sitio 
                   web “www.arriArceTCI.com”, y rechaza cualquier responsabilidad derivada de estas posibles irregularidades en la información</p>
                <p>ARRIARCE TCI se reserva el derecho de modificar, ampliar o eliminar los contenidos de este SITIO WEB “www.arriArceTCI.com”, en cualquier momento y sin previo aviso.</p>

                <h4>Artículo 2. Recomendación de productos</h4>
                <p>ARRIARCE TCI está orientada a ofrecer a sus clientes recomendación sobre los mejores productos buscando el máximo equilibrio entre jugabilidad, valor educativo y calidad. Para ello, le enviaremos periódicamente recomendaciones de productos a su dirección de correo electrónico.</p>
                <p>En cualquier momento puede solicitar la baja al servicio, enviando un correo a, info@arriArce.com, o llamando al número de atención al cliente 91 467 88 41.</p>
                <p>ARRIARCE TCI se reserva el derecho de enviar recomendaciones de productos de terceros a los correos de los clientes.</p>

                <h4>Artículo 3. Responsabilidad Limitada</h4>
                <p>ARRIARCE TCI no se responsabiliza del uso que Ud. pueda hacer de la información y servicios disponibles en este sitio web “www.arriArceTCI.com”, ni de posibles infracciones de derechos de terceros que puedan cometerse por 
                   personas que hagan uso de forma fraudulenta de los permisos de acceso a este sitio web “www.arriArceTCI.com”</p>
                <p>El interés de ARRIARCE TCI es que este sitio web “www.arriArceTCI.com”, funcione en perfectas condiciones y cumpla la finalidad para la que ha sido creado, ampliamente descrita en el propio sitio web “www.arriArceTCI.com”. 
                   En ningún caso ARRIARCE TCI, sus proveedores o terceras empresas mencionadas en este SITIO WEB se responsabiliza de posibles anomalías en su funcionamiento (interrupciones del servicio, virus informáticos, sobrecargas en Internet...), 
                   ni de que esta finalidad no coincida con el uso que Ud. quiere hacer de él. Ante posibles incorrecciones o mal funcionamientos que pueda detectar, le rogamos que lo comunique a ARRIARCE TCI, quién pondrá todo su esfuerzo en su solución 
                   con los máximos medios de que disponga y a la mayor brevedad posible.</p>
                <p>Los precios de este sitio web “www.arriArceTCI.com”, son meramente informativos, y en ningún caso vinculantes. Estos precios pueden variar sin previo aviso. En el caso de cursar un pedido, ARRIARCE TCI le confirmará por e-mail los 
                   precios y disponibilidad de los productos solicitados. ARRIARCE TCI no se responsabiliza de posibles errores que se puedan producir en los precios y las descripciones de los productos.</p>
                
                <h4> Artículo 4. Política de privacidad y confidencialidad de datos</h4>
                <p>A los efectos de lo dispuesto en la Ley Orgánica 15/1999, de 13 de Diciembre, de Protección de Datos de Carácter Personal, ARRIARTE TCI le informa de la existencia de un fichero de datos de carácter personal creado por y bajo la 
                   responsabilidad de ARRIARCE TCI con los datos obtenidos en “www.arriArceTCI.com”, estando inscrito dicho espacio web en la Agencia Nacional de Protección de Datos.</p>
                <p>La finalidad de dicho fichero es remitir el pedido a la persona y dirección correctas, atender las solicitudes que nos plantee, mandarle toda información necesaria sobre sus compras y proporcionarle información acerca de los 
                   productos de “www.arriArceTCI.com” así como la realización de actividades promocionales por correo electrónico o por cualquier otro medio de comunicación electrónica equivalente (como SMS).</p>
                <p>En cumplimiento de lo establecido en la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal, en cualquier momento, Usted tendrá el derecho de acceso, rectificación, cancelación y oposición a todos sus datos 
                   personales incluidos en los distintos formularios de alta. Para ejercitar esta facultad Usted deberá enviar email a info@arriArce.com o bien por correo postal a ARRIARCE TCI, Ronda Don Bosco, 14 - 28044 MADRID (ESPAÑA) , 
                   indicando su nombre, apellido(s) y email de usuario, adjuntando fotocopia de su DNI.</p>
                <p>ARRIARCE TCI se compromete a guardar la máxima reserva y confidencialidad sobre la información que le sea facilitada y a utilizarla únicamente para los fines indicados.</p>
                <p>Conforme a la legislación vigente en materia de protección de datos, ARRIARCE TCI se compromete a adoptar los niveles de seguridad adecuados a los datos facilitados por sus clientes y, además, a instalar todos los medios y 
                   medidas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y extracción de los mismos.</p>
                <p>Conforme a lo establecido en la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal, le informamos de que el cumplimiento de cualquier formulario existente en el Sitio Web “www.arriArceTCI.com” o la remisión de un correo
                   electrónico a cualquiera de nuestros buzones implica la aceptación de esta política de privacidad, así como la autorización a ARRIARCE TCI para que trate los datos personales que nos facilite.</p>
                <p>Por la mera visita a la web, los Usuarios no facilitan información personal alguna ni queda obligado a facilitarla. Los clientes y usuarios se comprometen a navegar por la página web y a utilizar el contenido de forma correcta, no 
                   realizando ningún tipo de acción fraudulenta sobre la misma.</p>
                <p>ARRIARCE TCI presume que los datos han sido introducidos por su titular o por persona autorizada por éste, así como que son correctos y exactos. Corresponde a Usted la actualización de sus propios datos. Por lo tanto, Usted es 
                   responsable de la veracidad de los datos y ARRIARCE TCI no será responsable de la inexactitud de los datos personales de sus clientes.</p>
                <br/><br/><br/>    
            </div>
        </section>
    );
};

export default LegalWarning;






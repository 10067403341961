import React from 'react';
import Service2Header from './Service2Section';
import background1 from '../../assets/img/reparacion.jpg';
import background2 from '../../assets/img/portada.jpg';
import background3 from '../../assets/img/dispositivos.jpg';
import ProductSection from './ProductSection';
const Service2 = () => {
  return (
    <div>
      <Service2Header /> 

      <section className="price container">
        <br></br><br></br><br></br><br></br>
        <h2 className="subtitle"style={{ color: '#0056b3' }}>NUESTRAS SOLUCIONES DE REPARACION</h2>
        <p>Ofrecemos servicios de reparación especializados y eficientes para abordar una amplia variedad de problemas. Desde reparaciones de hardware, 
          como pantallas, carcasas y placas base, hasta soluciones de software, que incluyen sistemas operativos, limpieza de virus y configuración de redes. 
          Confía en nuestro equipo capacitado y en nuestras avanzadas técnicas para devolver tus dispositivos a su mejor estado.</p>
        <br></br>
        <br></br>

        <div className="price__table">
          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background1})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>REPARACIONES (HARDWARE)</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Nos especializamos en la reparación de hardware para una variedad de dispositivos, incluyendo 
              ordenadores, laptops y más. Ofrecemos servicios expertos para solucionar problemas en pantallas, carcasas, placas base, teclados, conectores de carga, 
              bisagras, baterías, ventiladores, fuentes de alimentación, tarjetas gráficas y memoria RAM. Nuestro equipo utiliza métodos avanzados y piezas de calidad
               para garantizar soluciones duraderas y eficientes.</p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${background2})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>REPARACIONES/MEJORAS (SOFTWARE)</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Nuestros servicios de reparación y mejoras de software están diseñados para optimizar el rendimiento
              de tus dispositivos. Cubrimos una amplia gama de aspectos, incluyendo sistemas operativos, drivers, puesta a punto de equipos, 
              limpieza de virus, recuperación de datos, instalación de programas de ofimática, configuración de redes, copias de seguridad, configuración de Servidores
               NAS, teletrabajo, implantación de la Nube y configuración de correo electrónico. </p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(${background3})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>OTROS DISPOSITIVOS(IOS y ANDROID)</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>En nuestra área de especialización, ofrecemos una amplia gama de servicios para abordar 
              todas tus necesidades tecnológicas. Desde reparaciones de hardware hasta mejoras de software, nuestro equipo altamente capacitado y certificado 
              está listo para proporcionar soluciones precisas y eficientes. Confía en nosotros para mantener tus dispositivos en óptimas condiciones
               y garantizar un rendimiento excepcional.
              </p>
            </div>
          </div>
        </div>
      </section>
      <br></br><br></br>
      <ProductSection />

    </div>
  );
};

export default Service2;
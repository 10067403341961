import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import menu from '../assets/menu.svg';
import logo from '../assets/img/logo.png';
import Submenu from './Submenu';

const ScrolledHeader = ({ visible, isMenuOpen, handleMenuClick }) => {
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const headerRef = useRef();

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  const handleOutsideClick = (event) => {
    if (!headerRef.current.contains(event.target)) {
      setSubmenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const visibilityClass = visible ? 'visible' : '';

  return (
    <header className={`scrolled-header ${visibilityClass}`} ref={headerRef}>
      <nav className="nav container">
      <div className="nav__logo">
            <h2 className="nav__title">
              <NavLink to="/"   className="nav__links">
                <img src={logo} className="nav__img_logo" alt="menu" />
              </NavLink>
            </h2>
          </div>

        <div className={`nav__menu ${isMenuOpen ? 'isMenuOpen' : ''}`} onClick={handleMenuClick}>
          <img src={menu} className="nav__img" alt="menu" />
        </div>

        <ul className={`nav__link nav__link--menu ${isMenuOpen ? 'isMenuOpen' : ''}`}>
          <li className="nav__items">
            <div onClick={toggleSubmenu} className="nav__links">
              SERVICIOS
            </div>
            {isSubmenuOpen && (
              <Submenu
                className={isSubmenuOpen ? 'isSubmenuOpen' : ''}
                onClose={() => setSubmenuOpen(false)}
              />
            )}
          </li>
          <li className="nav__items">
            <NavLink to="/quieres-mas-informacion"  className="nav__links">
              CONTACTO
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default ScrolledHeader;





// Contact.js
import React from 'react';
import ContactHeader from './Contact/ContactHeader';
import FormsContact from './Contact/FormsContact';

const Contact = () => {
  return (
    <div>
      <ContactHeader />
      <FormsContact />
    </div>
  );
};

export default Contact;


import React from 'react';
import { NavLink } from 'react-router-dom';
import fot1 from '../assets/llave.svg'
import fot2 from '../assets/shapes.svg'
import fot3 from '../assets/chip.svg' 

const ServiceSection = () => {
  return (
    <section className="container about ">
      
      <div className="about__main">
        <article className="about__icons">
          <div className="about__text-section">
            <h3 className="about__title">SERVICIOS Y SISTEMAS IT</h3>
            <p className="about__paragrah">Potenciamos operaciones con servicios y sistemas IT eficientes, cubriendo desde soluciones innovadoras hasta componentes esenciales.</p>
            <NavLink to="/servicios-y-sistemas-it"  className="cta">
              Leer mas ....
            </NavLink>
          </div>
          <div className="about__image-section">
            <img src={fot3} className="about__icon" alt="Icono" />
          </div>
        </article>

        <article className="about__icons">
          <div className="about__text-section">
            <h3 className="about__title">REPARACION DE EQUIPOS</h3>
            <p className="about__paragrah">Restauramos el funcionamiento óptimo de dispositivos mediante diagnóstico preciso y acciones correctivas, abarcando desde electrónicos hasta mecánicos.</p>
            <NavLink to="/reparacion-de-equipos"  className="cta">
              Leer mas ....
            </NavLink>
          </div>
          <div className="about__image-section">
            <img src={fot1} className="about__icon" alt="Icono" />
          </div>
        </article>

        <article className="about__icons">
          <div className="about__text-section">
            <h3 className="about__title">SOLUCIONES DE NEGOCIO</h3>
            <p className="about__paragrah">Optimizamos tu empresa con soluciones integrales de negocio.</p>
            <NavLink to="/soluciones-de-negocio"  className="cta">
              Leer mas ....
            </NavLink>
          </div>
          <div className="about__image-section">
            <img src={fot2} className="about__icon" alt="Icono" />
          </div>
        </article>
        
      </div>
    </section>
  );
};

export default ServiceSection;


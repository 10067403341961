import React from 'react';
import Service3Header from './Service3Section';
import background1 from '../../assets/img/analisis.jpg';
import background2 from '../../assets/img/implementacion.jpg';
import background3 from '../../assets/img/asesoramiento.jpg';
const Service3 = () => {
  return (
    <div>
      <Service3Header /> 

      <section className="price container">
        <br></br><br></br><br></br><br></br>
        <h2 className="subtitle"style={{ color: '#0056b3' }}>TRANSFORMACIÓN EMPRESARIAL A TU ALCANCE</h2>
        <p>Experimenta una transformación empresarial completa con nuestras soluciones integrales. Estamos 
            comprometidos en potenciar tu crecimiento y éxito mediante estrategias personalizadas que abarcan
             desde la optimización de procesos hasta la implementación de tecnologías avanzadas.</p>
        <br></br>
        <br></br>

        <div className="price__table">
          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background1})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>ANÁLISIS PERSONALIZADO</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Ofrecemos un análisis exhaustivo de tu empresa para comprender tus necesidades específicas. 
              Esta evaluación nos permite proporcionar soluciones personalizadas que se alinean perfectamente con los objetivos y desafíos de tu negocio.</p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${background2})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>IMPLEMENTACIÓN EFICIENTE</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Nuestras soluciones de negocio se implementan con eficiencia y precisión. 
              Nos aseguramos de que cada componente se integre de manera sinérgica en tu infraestructura existente, minimizando interrupciones y 
              optimizando la eficiencia operativa. </p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(${background3})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.9 }}>
            <h3 className="price__name" style={{ color: '#fff' }}><strong>ASESORAMIENTO ESTRATÉGICO</strong></h3>
            <div className="price__items">
              <p className="price__features" style={{ color: '#fff' }}>Además de proporcionar soluciones técnicas, brindamos asesoramiento estratégico. 
              Nuestro equipo te guiará en la toma de decisiones clave, ofreciendo perspectivas valiosas para mejorar los procesos, aumentar la productividad y alcanzar tus metas comerciales.
              </p>
            </div>
          </div>
        </div>
      </section>
      <br></br><br></br>
      <br></br><br></br>
    </div>
  );
};

export default Service3;
import React, { useState } from 'react';

import {
    validateNombre,
    validateApellido,
    validateEmail,
    validateTelefono,
    validateComentarios,
    validatePoliticaPrivacidad,
} from './validations';

const FormsContact = () => {
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [comentarios, setComentarios] = useState('');
    const [aceptarPolitica, setAceptarPolitica] = useState(false);

    const [errorMessages, setErrorMessages] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        comentarios: '',
        aceptarPolitica: '',
    });

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const nombreError = validateNombre(nombre);
        const apellidoError = validateApellido(apellido);
        const emailError = validateEmail(email);
        const telefonoError = validateTelefono(telefono);
        const comentariosError = validateComentarios(comentarios);
        const politicaPrivacidadError = validatePoliticaPrivacidad(aceptarPolitica);

        setErrorMessages({
            nombre: nombreError,
            apellido: apellidoError,
            email: emailError,
            telefono: telefonoError,
            comentarios: comentariosError,
            aceptarPolitica: politicaPrivacidadError,
        });

        if (nombreError || apellidoError || emailError || telefonoError || comentariosError || politicaPrivacidadError) {
            return;
        }

        const solicitud = {
            nombre,
            apellido,
            email,
            telefono,
            comentarios,
            aceptarPolitica,
        };

        try {
            // Enviar datos al backend
            const response = await fetch('/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(solicitud),
            });
            
            

            // Manejar la respuesta del backend
            if (response.ok) {
                setIsFormSubmitted(true); // Mostrar el mensaje de éxito
                setNombre('');
                setApellido('');
                setEmail('');
                setTelefono('');
                setComentarios('');
                setAceptarPolitica(false);
            } else {
                console.error('Error al enviar el formulario al backend');
            }
        } catch (error) {
            console.error('Error de red:', error);
        }
        
    };

    return (
        <section className="container-about">
            <div className="info-section">
                <h1>¿QUIERES MÁS INFORMACIÓN?</h1>
                <p>Si busca obtener más información sobre nosotros, la gama completa de productos y 
                    servicios que ofrecemos, le invitamos cordialmente a ponerse en contacto con nosotros. Estamos 
                    comprometidos en brindarle asesoramiento especializado y ofrecer soluciones estratégicas.Esperamos con 
                    interés la oportunidad 
                    de colaborar y contribuir al crecimiento sostenible de su empresa. </p>
            </div>

            {isFormSubmitted && (
                <div className="overlay">
                    <div className="success-message">
                        El formulario se ha enviado exitosamente. Nos pondremos en contacto con usted.
                        <button onClick={() => setIsFormSubmitted(false)}>Cerrar</button>
                    </div>
                </div>
            )}

            <form className="form" onSubmit={handleSubmit}>
                <div className="input-fields-section">
                    <div className="input-field">
                        <input type="text" name="nombre" id="nombre" placeholder="Nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
                        <span className="error-message">{errorMessages.nombre}</span>
                    </div>
                    <div className="input-field">
                        <input type="text" name="apellido" id="apellido" placeholder="Apellido" value={apellido} onChange={e => setApellido(e.target.value)} />
                        <span className="error-message">{errorMessages.apellido}</span>
                    </div>
                    <div className="input-field">
                        <input type="text" name="email" id="email" placeholder="Correo electrónico" value={email} onChange={e => setEmail(e.target.value)} />
                        <span className="error-message">{errorMessages.email}</span>
                    </div>
                    <div className="input-field">
                        <input
                            type="text"
                            name="telefono"
                            id="telefono"
                            placeholder="Teléfono"
                            value={telefono}
                            onChange={e => setTelefono(e.target.value)}
                            autoComplete="off" // Desactivar el autocompletado
                        />
                        <span className="error-message">{errorMessages.telefono}</span>
                    </div>
                </div>

                <div className="comments-and-submit-section">
                    
                    <textarea
                        name="comentarios"
                        id="comentarios"
                        placeholder="Comentarios"
                        value={comentarios}
                        onChange={e => setComentarios(e.target.value)}
                    />
                    <span className="error-message" style={{ marginLeft: '5%' }}>{errorMessages.comentarios}</span>

                    <div>
                        <input
                            type="checkbox"
                            id="accept"
                            name="accept"
                            checked={aceptarPolitica}
                            onChange={(e) => {
                                if (typeof e.target.checked === 'boolean') {
                                    setAceptarPolitica(e.target.checked);
                                }
                            }}
                        />
                        <label htmlFor="accept">He leído y acepto la política de privacidad</label>
                        {errorMessages.aceptarPolitica && (
                            <span className="error-message" style={{ marginLeft: '5%' }}>{errorMessages.aceptarPolitica}</span>
                        )}
                    </div>
                    <input type="submit" name="register" value="Enviar" />
                </div>
            </form>
        </section>
    );
};

export default FormsContact;





// ContactHeader.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import menu from '../assets/menu.svg';
import Submenu from '../Header/Submenu';  
import ScrolledHeader from '../Header/ScrolledHeader';  

const ContactHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [showScrolledHeader, setShowScrolledHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      setShowScrolledHeader(show);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  const headerStyles = {
    backgroundColor: '#2091F9',
    width: '99.4vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    display: 'grid',
    gridTemplateRows: '100px 1fr',
    color: '#fff',
  };

  return (
    <div>
      {showScrolledHeader && <ScrolledHeader handleMenuClick={handleMenuClick} isMenuOpen={isMenuOpen} />}
      
      <header style={headerStyles}>
      <nav className="naver">
          <div className="naver__logo">
            <h2 className="naver__title">
              <NavLink to="/" className="naver__links">
                <img src={logo} className="naver__img_logo" alt="menu" />
              </NavLink>
            </h2>
          </div>

          <div className={`naver__menu ${isMenuOpen ? 'isMenuOpen' : ''}`} onClick={handleMenuClick}>
            <img src={menu} className="naver__img" alt="menu" />
          </div>

          <ul className={`naver__link naver__link--menu ${isMenuOpen ? 'isMenuOpen' : ''}`}>
            <li className="naver__items">
              <div onClick={toggleSubmenu} className="naver__links">
                SERVICIOS
              </div>
              {isSubmenuOpen && (
                <Submenu
                  className={isSubmenuOpen ? 'isSubmenuOpen' : ''}
                  onClose={() => setSubmenuOpen(false)}
                />
              )}
            </li>
            <li className="naver__items">
              <NavLink to="/quieres-mas-informacion" className="naver__links">
                CONTACTO
              </NavLink>
            </li>
          </ul>
        </nav>
        <br></br>
      </header>
    </div>
  );
};

export default ContactHeader;

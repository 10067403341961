import React from 'react';
import mensaje from '../../assets/mensaje.svg';
import recompensa from '../../assets/recompensa.svg';
import gente from '../../assets/gente.svg';

const Service2 = () => {
  return (
    <div>
      <section className="price container">
        <br></br><br></br><br></br>
        <h2 className="subtitle" style={{ color: '#0056b3' }}>SOMOS EXPERTOS EN SERVICIOS Y SISTEMAS IT</h2>
        <br></br>
        <div className="price__table">
          <div className="price__element" style={{ backgroundColor: '#ffffff', color: '#000000', width: '300px', marginRight: '20px' }}>
            <h4 className="price__name"><strong>PROFESIONALES CAPACITADOS</strong></h4>
            <div className="price__items">
              <p className="price__features">Equipo en constante formación y certificado por el fabricante" podría ser "Profesionales actualizados y con certificación oficial del fabricante</p>
              <p><img src={gente} className="submenu__icon" alt="Icono" /></p>
            </div>
          </div>

          <div className="price__element" style={{ backgroundColor: '#ffffff', color: '#000000', width: '300px', marginRight: '20px' }}>
            <h4 className="price__name"><strong>OPTAMOS POR LA EXCELENCIA</strong></h4>
            <div className="price__items">
              <p className="price__features">Implementamos iniciativas fundamentadas en las principales soluciones del mercado.</p>
              <p style={{ marginTop: '60px' }}>
                <img src={recompensa} className="submenu__icon" alt="Icono" />
              </p>

            </div>
          </div>

          <div className="price__element" style={{ backgroundColor: '#ffffff', color: '#000000', width: '300px' }}>
            <h4 className="price__name"><strong>COMUNICACION CONTINUA</strong></h4>
            <div className="price__items">
              <p className="price__features">Colaboración directa con el fabricante para la ejecución exitosa de proyectos.</p>
              <p style={{ marginTop: '58px' }}><img src={mensaje} className="submenu__icon" alt="Icono" /></p>
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {/* Agrega aquí el código SVG */}
        </div>
      </section>
    </div>
  );
};

export default Service2;

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import menu from '../../assets/menu.svg';
import logo from '../../assets/img/logo.png';
import Submenu from '../../Header/Submenu';  
import ScrolledHeader from '../../Header/ScrolledHeader';  

const Service3Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [showScrolledHeader, setShowScrolledHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      setShowScrolledHeader(show);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <div>
      {showScrolledHeader && <ScrolledHeader handleMenuClick={handleMenuClick} isMenuOpen={isMenuOpen} />}

      <header className="herosNegocio">
      <nav className="naver">
          <div className="naver__logo">
            <h2 className="naver__title">
              <NavLink to="/" className="naver__links">
                <img src={logo} className="naver__img_logo" alt="menu" />
              </NavLink>
            </h2>
          </div>

          <div className={`naver__menu ${isMenuOpen ? 'isMenuOpen' : ''}`} onClick={handleMenuClick}>
            <img src={menu} className="naver__img" alt="menu" />
          </div>

          <ul className={`naver__link naver__link--menu ${isMenuOpen ? 'isMenuOpen' : ''}`}>
            <li className="naver__items">
              <div onClick={toggleSubmenu} className="naver__links">
                SERVICIOS
              </div>
              {isSubmenuOpen && (
                <Submenu
                  className={isSubmenuOpen ? 'isSubmenuOpen' : ''}
                  onClose={() => setSubmenuOpen(false)}
                />
              )}
            </li>
            <li className="naver__items">
              <NavLink to="/quieres-mas-informacion" className="naver__links">
                CONTACTO
              </NavLink>
            </li>
          </ul>
        </nav>

        <section className="hero__container container">
          <h1 className="hero__title">SOLUCIONES DE NEGOCIO</h1>
          <p className="hero__paragraph">
          Proporcionamos soluciones integrales de negocio personalizadas para impulsar el crecimiento y el éxito 
          empresarial. Desde la optimización de procesos hasta la implementación de tecnologías avanzadas, 
          nos centramos en tus metas para mejorar la eficiencia, aumentar la productividad y lograr el máximo rendimiento.</p>
        </section>
      </header>
      <br></br><br></br><br></br><br></br>
    </div>
  );
};

export default Service3Header;
import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import fot1 from '../assets/llave.svg';
import fot2 from '../assets/shapes.svg'
import fot3 from '../assets/chip.svg' 

const Submenu = ({ className, onClose }) => {
  const submenuRef = useRef();

  const handleSubmenuClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        onClose(); // Cierra el submenu llamando a la función onClose pasada como prop
      }
    };

    const handleDocumentMouseDown = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener('mousedown', handleDocumentMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown);
    };
  }, [submenuRef, onClose]);

  return (
    <ul ref={submenuRef} className={`submenu ${className}`} onClick={handleSubmenuClick}>
      <li className="submenu__item">
        <div className="submenu__image-section">
          <img src={fot3} className="submenu__icon" alt="Icono" />
        </div>
        <NavLink to="/servicios-y-sistemas-it"  className="submenu__links">
         SERVICIOS Y SISTEMAS IT
        </NavLink>
      </li>

      <li className="submenu__item">
        <div className="submenu__image-section">
          <img src={fot1} className="submenu__icon" alt="Icono" />
        </div>
        <NavLink to="/reparacion-de-equipos"  className="submenu__links">
          REPARACION DE EQUIPOS
        </NavLink>
      </li>

      <li className="submenu__item">
        <div className="submenu__image-section">
          <img src={fot2} className="submenu__icon" alt="Icono" />
        </div>
        <NavLink to="/soluciones-de-negocio"  className="submenu__links">
          SOLUCIONES DE NEGOCIO
        </NavLink>
      </li>
    </ul>
  );
};

export default Submenu;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Service1 from './components/Services/IT/Servicio1';
import Service2 from './components/Services/sofware/Servicio2';
import Service3 from './components/Services/solution/Servicio3';
import Contact from './components/Contact';
import Legal from './components/Legal';
import Privacy from './components/Privacy';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<><Header /><Home /></>} />
          <Route path="/servicios-y-sistemas-it" element={<Service1 />} />
          <Route path="/reparacion-de-equipos" element={<Service2 />} /> 
          <Route path="/soluciones-de-negocio" element={<Service3 />} />  
          <Route path="/quieres-mas-informacion" element={<Contact />} />
          <Route path="/aviso-legal" element={<Legal />} />
          <Route path="/politica-de-privacidad" element={<Privacy />} />
          {/* Ruta por defecto que redirige a la página principal si la ruta no es válida */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;





import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="container-about">
        <div className="info-section">
            <h1>Politica de Privacidad</h1>
        </div>
        <div className="input-fields-section-legal">
            <p>En relación al cumplimiento de la Ley de Protección de Datos, le informamos que los datos personales facilitados por Ud. en cualquiera de los formularios incluidos en este sitio web (http://www.arriArceTCI.com) 
               son incluidos en unos ficheros informáticos propiedad y responsabilidad de ARRIARCE TCI, S.L., inscrita en el Registro Mercantil de Madrid tomo 14.893, libro 0, folio 111, sección 8, hoja M-247.817, inscripción 1ª - CIF: B-82499294 
               (en adelante ARRIARCE TCI) y serán tratados por métodos automatizados con la única finalidad de hacer posible la gestión administrativa y comercial de nuestras relaciones comerciales.</p>
            <p>Los nombres, marcas, y logotipos propiedad de terceros, expuestos en este sitio web, están protegidos por sus respectivos dueños.</p>
            <p>El usuario autoriza expresamente a ARRIARCE TCI para que cuantos datos se obtengan en este sitio web para el desarrollo de las relaciones comerciales entre ambas parte puedan ser facilitados a terceras empresas para el mejor 
               cumplimiento de sus servicios (transportistas, entidades financieras, etc.). En todos los casos serán los estrictamente necesarios para la actividad concreta que se vaya a realizar. Así mismo, el usuario autoriza a ARRIARCE TCI 
               para que los datos recibidos sean utilizados para la realización de campañas informativas y acciones promociónales de productos o servicios que puedan resultar de su interés.</p>
            <p>En cualquier momento, el usuario podrá ejercer los derechos de acceso, rectificación, cancelación y oposición que le otorga la vigente Ley 15/1999 de Protección de Datos, simplemente notificándonoslo por teléfono, correo, fax o e-mail.</p>
            <ul>
              <li>Dirección: Ronda Don Bosco, 14 - 28044 MADRID - España</li>
              <li>Teléfono: (+34) - 91 467 88 41</li>
              <li>E-mail: info@arriArce.com</li>
            </ul>
            <p>ARRIARCE TCI se reserva el derecho de modificar su POLÍTICA DE PRIVACIDAD o las condiciones de uso de nuestros servicios por motivos de adaptación a la legislación vigente, u otros motivos, por lo que se recomienda al usuario de este sitio 
               web la revisión periódica de esta página, ya que el uso de este sitio por parte del usuario se entenderá como la aceptación de la POLÍTICA DE PRIVACIDAD o condiciones de uso vigentes en ese momento.</p>
            <br/><br/><br/>    
        </div>
    </section>
  );
};
export default PrivacyPolicy;

import React from 'react';
import computerImage from '../../assets/img/tecnico.png';

const ProductSection = () => {
    return (
      <section className="product">
        <div className="product__main">
          <div className="product__image-section">
            <img
              src={computerImage}
              className="product__image"
              alt="Reparación de ordenadores"
            />
          </div>
  
          <div className="product__text-section">
            <div className="product__text">
              <h3 className="product__title">¿Por qué elegir a ARRIARCE TCI?</h3>
              <p className="product__paragraph">Nos formamos de manera constante en las últimas tecnologías.</p>
              <ul>
                <li><strong>Experiencia Ágil: </strong> Con más de dos décadas de experiencia, brindamos respuestas rápidas y eficientes a las necesidades de nuestros clientes.</li>
                <li><strong>Equilibrio Calidad/Servicio: </strong> Nuestra política incluye una evaluación gratuita, permitiendo a los clientes pagar únicamente por los servicios esenciales.</li>
                <li><strong>Eficiencia Técnica:</strong> Mantenemos y reparamos equipos con la máxima eficiencia, gracias a la alta cualificación de nuestros técnicos. </li>
                <li><strong>Reputación Sólida: </strong> Contamos con la confianza de cientos de clientes satisfechos por comunicarnos de manera clara y proporcionamos asesoramiento adaptado a las necesidades individuales </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

export default ProductSection;

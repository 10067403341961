import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import fot3 from '../components/assets/ubicacion.svg' 

const Footer = () => {

  return (
    <footer>
      <div className="footer">
        <div>
          <h3>ARRIARCE TCI</h3>
          <p>Somos una consultora tecnológica formada por diferentes divisiones y marcas referentes, creando a través de la especialización en áreas tecnológicas y estratégicas un entorno de valor para nuestros clientes diferencial en el sector.</p>
          <p><strong>Hablanos</strong>   www.arriarcetci.com</p>
          <p><strong>Contactanos</strong>   (+34) 91 467 88 41</p>
          <div className="about__image-section_footer">
            <p></p>
            <img src={fot3} className="about__icon_footer" alt="Icono" />
            <p>Ronda Don Bosco, 14 - 28044 MADRID</p>
          </div>
        </div>
        <div className="button-container">
          <Link to="/quieres-mas-informacion"  className="buttonFooter">
            ¿Hablamos?
          </Link>
        </div>
      </div>
      <div className="centered-section">
        <div className="footer-links">
          <NavLink to="/politica-de-privacidad" className="footer-link">Privacidad</NavLink>
          <span className="separator">-</span>
          <NavLink to="/aviso-legal" className="footer-link">Aviso Legal</NavLink>
        </div>
        <br/>
        <p>Copyright © 2023 ARRIARCE TCI. Todos los derechos reservados.</p>
      <div/>
    </div>
    </footer>
  );
}

export default Footer;

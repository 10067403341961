// Contact.js
import React from 'react';
import ContactHeader from './Contact/ContactHeader';
import PrivacyPolicy from './Legaly/PrivacyPolicy';

const Privacy = () => {
  return (
    <div>
      <ContactHeader />
      <PrivacyPolicy />
    </div>
  );
};

export default Privacy;